<template>
    <div>
        <div class="body-w100 d-flex justify-content-between">
            <div class="col-12 col-md-4" style="padding-right:20px">
                <v-chart v-if="loaded" class="chart" :autoresize="true" :option="nb_decisions_opt" @click="handleClickDecision"></v-chart>
            </div>
            <div class="col-12 col-md-8">
                <v-chart v-if="loaded" class="chart" :autoresize="true" :option="data_chart_opt" @click="handleClickDecisionAndTherapeuticArea"></v-chart>
            </div>
        </div>
    </div>
</template>
    
<script>

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from "echarts/components";
import VChart from "vue-echarts";
import _ from 'lodash'

use([
  CanvasRenderer,
  BarChart,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);

export default {
    name: "TabOverviewGraph",
    components: {
        VChart
    },
    data() {
        return {
            data_chart_opt: {
                title: {
                    text: '',
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                legend: {
                    bottom: '3%',
                    data: ['Ongoing', 'Published', 'Suspended']
                },
                grid: {
                    containLabel: true,
                },
                xAxis: {
                    type: 'value'
                },
                yAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        formatter: function(value) {
                            const strSplitted = value.split('(')
                            if(strSplitted.length > 1 && !strSplitted[1].includes('+'))
                                return strSplitted[0] + ' {a|(' + strSplitted[1] + '}'
                            else if(strSplitted.length > 2 && strSplitted[1].includes('+')) {
                                let category_name = strSplitted[0] + ' (' + strSplitted[1]
                                let remainingStr = strSplitted
                                remainingStr.splice(0,2)
                                return category_name + ' {a|(' + remainingStr.join(' (') + '}'
                            } else 
                                return value
                        },
                        rich: {
                            a: {
                                fontSize: '8px',
                                fonStyle: 'italic'
                            },
                        }
                    },
                    inverse: true,
                    triggerEvent:true
                },
                series: [
                    {
                        name: 'Ongoing',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: []
                    },
                    {
                        name: 'Published',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false,
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: []
                    },
                    {
                        name: 'Suspended',
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: false
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        barMinHeight: '50px',
                        data: []
                    }
                ]
            },
            nb_decisions_opt: {
                title: {
                    text: 'Number of decisions',
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: '3%',
                    left: 'center'
                },
                series: [
                    {
                        name: 'Decision',
                        type: 'pie',
                        radius: ['40%', '70%'],
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: []
                    }
                ]
            },
            drugname_matching_table: [],
            loaded: false,
        }
    },
    methods: {
        filterDecisions(name = null, type = null) {
            let params = {}
            if(name){
                if(this.drugname_matching_table[name]){
                    if(this.drugname_matching_table[name].category)
                        name = this.drugname_matching_table[name].category
                    else if(this.drugname_matching_table[name].dci)
                        name = this.drugname_matching_table[name].dci
                }
                params[this.decisions_status.field] = name
            }
            if(type)
                params['decision'] = type

            this.$emit('request_result_agency', {order_by:'decision_date_num', sort:'DESC', graph_filter: true, ...params })
        },

        sortDecisions() {
            var sortableDecisions = [];
            const decisions = this.decisions_status.decisions
            for (let key in decisions) {
                if (Object.prototype.hasOwnProperty.call(decisions,key)) {
                    sortableDecisions.push([key, decisions[key]]);
                }
            }

            sortableDecisions.sort(function (a, b) {
                const totalA = a[1].ongoing + a[1].published + a[1].suspended
                const totalB = b[1].ongoing + b[1].published + b[1].suspended
                    if(totalA < totalB)
                    return 1
                if (totalA > totalB)
                    return -1
                return 0;
            });
            return sortableDecisions
        },

        setDataEChart(){
            if(this.decisions_status && !_.isEmpty(this.decisions_status.decisions)){
                this.loaded = true
                const that = this
                const decisions = that.sortDecisions()
                this.data_chart_opt.yAxis.data = []
                this.data_chart_opt.series.forEach(serie => {
                    serie.data = []
                })
                decisions.forEach((decision, i) => {
                    if(i < 10){
                        let key = decision[0]
                        const value = decision[1]
                        this.data_chart_opt.yAxis.data.push(key)
                        if(value.category || value.dci)
                            this.drugname_matching_table[key] = {'dci': value.dci, 'category': value.category}
                        this.data_chart_opt.series.forEach(serie => {
                            if(serie.name === 'Ongoing')
                                serie.data.push(value.ongoing)

                            if(serie.name === 'Published')
                                serie.data.push(value.published)

                            if(serie.name === 'Suspended')
                                serie.data.push(value.suspended)
                        })
                    }
                })

                this.nb_decisions_opt.series[0].data = [
                    {value: this.decisions_status.total_decisions.ongoing, name: 'Ongoing'},
                    {value: this.decisions_status.total_decisions.published, name: 'Published'},
                    {value: this.decisions_status.total_decisions.suspended, name: 'Suspended'}
                ]

                if(this.decisions_status.field === 'therapeutic')
                    this.data_chart_opt.title.text = 'Top 10 therapeutic areas per decision'
                else
                    this.data_chart_opt.title.text = 'Top 10 drugs per decision'
            }
        },
        handleClickDecision: function(params){
            this.filterDecisions(null, params.name)
        },
        handleClickDecisionAndTherapeuticArea: function(params){
            if(params.componentType === 'yAxis')
                this.filterDecisions(params.value)
            if(params.componentType === 'series')
                this.filterDecisions(params.name, params.seriesName)
        },
    },
    computed: {
        decisions_status() {
            return this.$store.getters['result/decisions_status']
        }
    },

    mounted() {
        this.setDataEChart()
    },

    watch: {
        decisions_status: function() {
            this.loaded = false
            this.setDataEChart()
        }
    }
}
</script>
<style scoped>
    .chart {
        height: 400px;
        background: #fff;
        padding: 20px;
        margin-bottom: 20px;
        min-width: 100%
    }
</style>